// extracted by mini-css-extract-plugin
export var ButtonWrapper = "Offres-module--ButtonWrapper--SOurb";
export var LineWrapper = "Offres-module--LineWrapper--VswW6";
export var LineWrapper2 = "Offres-module--LineWrapper2--H3059";
export var OfferInfo = "Offres-module--OfferInfo--FHlKW";
export var OfferInfos = "Offres-module--OfferInfos--pSXTW";
export var OffersInfos = "Offres-module--OffersInfos--CbZb8";
export var TableWrapper = "Offres-module--TableWrapper--57AC3";
export var TitleInfo = "Offres-module--TitleInfo--yuoEV";
export var TitleInfos = "Offres-module--TitleInfos--9tP9I";
export var Wrapper = "Offres-module--Wrapper--BEME4";