import React, { useEffect, useState } from "react"
import {
  Wrapper,
  TableWrapper,
  ButtonWrapper,

  /* HEADER */
  TitleInfos,
  TitleInfo,

  /* OFFERS */
  OffersInfos,

  /* OFFER */
  OfferInfos,
  OfferInfo,

  /**/
  LineWrapper,
  LineWrapper2,
} from "./Offres.module.css"
import { OrangeTitle } from "./../../../css/Titre.module.css"
import Popup from "./../../../components/Popup"
import Button from "./../../../components/Button"
import Edit from "./../../../res/icons/edit.png"
import Trash from "./../../../res/icons/trash.png"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import firebase from "gatsby-plugin-firebase"

const isBrowser = typeof window !== "undefined"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const HeaderOffersTable = () => {
  return (
    <div className={TitleInfos}>
      <p className={TitleInfo}>Titre</p>
      <p className={TitleInfo}>Commerce</p>
      <p className={TitleInfo}>Description</p>
      <p className={TitleInfo}>Categorie</p>
      <p className={TitleInfo}>Offre de bienvenue</p>
      <p style={{ width: "100px" }}>Modifier</p>
      <p style={{ width: "100px" }}>Supprimer</p>
    </div>
  )
}

const OffersInfosInTable = ({ offer, trashIsClicked }) => {
  const [shopName, setShopName] = useState("")

  useEffect(() => {
    firebase
      .firestore()
      .collection("shops")
      .doc(offer.shopId)
      .get()
      .then(doc => {
        setShopName(doc._delegate._document.data.value.mapValue.fields.name.stringValue)
      })
      .catch(error => {
        //console.log("Error getting document:", error)
      })
  }, [shopName])

  const getTextWelcomeOffer = () => {
    if (offer.welcomeOffer) {
      return "Oui"
    } else {
      return "Non"
    }
  }

  const editIsClicked = () => {
    if (isBrowser) {
      window.location.href = "/admin/offres/modifier?id=" + offer["id"]
    }
  }

  return (
    <div className={OfferInfos}>
      <p className={OfferInfo}>{offer.title}</p>
      <p className={OfferInfo}>{shopName}</p>
      <div className={OfferInfo}>
        <p style={{ width: "400px" }}>{offer.description}</p>
      </div>
      <p className={OfferInfo}>{offer.category}</p>
      <p className={OfferInfo}>{getTextWelcomeOffer()}</p>
      <div style={{ width: "100px" }}>
        <button
          style={{ border: "none", backgroundColor: "transparent" }}
          onClick={editIsClicked}
        >
          <img alt="edit" src={Edit} width="15px" height="15px" />
        </button>
      </div>
      <div style={{ width: "100px" }}>
        <button
          style={{ border: "none", backgroundColor: "transparent" }}
          onClick={trashIsClicked}
        >
          <img alt="trash" src={Trash} width="15px" height="15px" />
        </button>
      </div>
    </div>
  )
}

const Offres = ({ offers }) => {
  const [successSnackbarIsOpened, setSuccessSnackbarIsOpened] = useState(false)

  const [trashPopupIsOpened, setTrashPopupIsOpened] = useState(false)
  const [offerTrashSelected, setOfferTrashSelected] = useState(0)

  const toggleTrashPopup = idx => {
    setOfferTrashSelected(idx)
    setTrashPopupIsOpened(!trashPopupIsOpened)
  }

  const handleDeleteOffer = () => {
    toggleTrashPopup()
    firebase
      .firestore()
      .collection("offers")
      .doc(offers[offerTrashSelected].id)
      .delete()
      .then(() => {
        setSuccessSnackbarIsOpened(true)
      })
      .catch(error => {
        //console.log("error when deleting: ", error)
      })
  }

  const handleSuccessClose = () => {
    setSuccessSnackbarIsOpened(false)
  }

  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>Offres</h1>
      <div className={ButtonWrapper}>
        <Button alt="" onClick={() => window.location.href = "/admin/offres/ajouter"}>
          Ajouter
        </Button>
      </div>
      <div className={TableWrapper}>
        <HeaderOffersTable />
        <div className={LineWrapper}></div>
        <div className={OffersInfos}>
          {offers.map((offer, idx = 0) => (
            <>
              <OffersInfosInTable
                offer={offer}
                trashIsClicked={() => toggleTrashPopup(idx++)}
              />
              <div className={LineWrapper2}></div>
            </>
          ))}
        </div>
      </div>
      {trashPopupIsOpened && (
        <Popup
          title={"Attention !"}
          onClose={() => toggleTrashPopup()}
          question={"Êtes-vous sûr de vouloir supprimer cette offre ?"}
          onCancel={() => toggleTrashPopup()}
          onOk={() => handleDeleteOffer()}
        />
      )}
      <Snackbar
        open={successSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Bravo ! Tu as bien supprimé cette offre !
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Offres
